/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
export function getCookie(name: string): string {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (Array.isArray(parts) && parts.length === 2) {
    var r = parts.pop()!.split(";").shift();
    return r ? r : "";
  }
  return "";
}

export function setCookie(
  name: string,
  value: string,
  expiresMinutes: number = 60,
  path: string = "/"
) {
  document.cookie = `${name}=${value}; path=${path}; max-age=${
    60 * expiresMinutes
  };`;
}

export function deleteCookie(name: string, path: string = "/") {
  document.cookie = `${name}=nothing; path=${path}; max-age=0;`;
}

export function setObjectCookie(
  name: string,
  value: any,
  expiresMinutes: number = 60,
  path: string = "/"
) {
  value = JSON.stringify(value);
  setCookie(name, value, expiresMinutes);
}

export function getObjectCookie(name: string) {
  var value = getCookie(name);
  if (!value) return null;
  return JSON.parse(value);
}
