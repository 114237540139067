import { ReactNode, createContext, useReducer, Dispatch } from "react";
import { setObjectCookie, getObjectCookie, deleteCookie } from "../utils/CookieUtils";

export const AUTH_COOKIE_NAME = "auth";

export type AuthState = {
  isAuth: boolean;
  jwt?: string;
  username?: string;
};

type AuthActionType = {
  type: string;
  payload: AuthState | undefined | null;
};

function authReducer(state: AuthState, action: AuthActionType) {
  state = { ...state };
  try {
    switch (action.type) {
      case "login":
        state = action.payload as AuthState;
        setObjectCookie(AUTH_COOKIE_NAME, state, 240)
        break;
      case "logout":
        state.isAuth = false;
        state.jwt = undefined;
        state.username = undefined;
        deleteCookie(AUTH_COOKIE_NAME);
        break;
      default:
        throw Error("method not implemented");
    }
  } catch (err: any) {
    console.error(err);
  }
  return state;
}

export type AuthContextType = {
  state: any;
  dispatch: Dispatch<AuthActionType>;
};

export const AuthContext = createContext<AuthContextType>({
  state: null,
  dispatch: (() => undefined) as Dispatch<AuthActionType>,
});

export function AuthContextProvider({
  children,
}: {
  children: ReactNode[] | ReactNode;
}) {

  const [state, dispatch] = useReducer(authReducer, getObjectCookie(AUTH_COOKIE_NAME) ?? { isAuth: false });
  
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
