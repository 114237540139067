import React, {Dispatch, SetStateAction} from 'react';
import { SelectEl } from "@avinet/adaptive-ui-core/form/controls/Select";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import { TranslatorProvider } from "@avinet/adaptive-ui-translate";
import { TimeStepStats } from "./TimeStepStats";

type RightSidebarProps = {
    valueProperty: string;
    setValueProperty: Dispatch<SetStateAction<string>>,
    timeStepData : any,
    prodStats: any,
    ordColorScale: any
}

export function RightSidebar({valueProperty, setValueProperty, timeStepData, prodStats, ordColorScale} : RightSidebarProps) {
    return (
        <>
        <Row>
          <h4>Påvirket dyrkingsareal</h4>
        </Row>
        <TranslatorProvider
          translations={{
            locale: "no_nb",
            Form: {
              optional: "optional",
              singleLabel: "Single select",
              multiLabel: "Multi select",
              valuePropertyLabel: "Velg dyrkingsform",
            },
          }}
        >
          <SelectEl
            id="valueProperty"
            items={[
              { value: "area", label: "Totalt dyrkingsareal" },
              { value: "potatoes", label: "Dyrkingsareal for poteter" },
              { value: "barley", label: "Dyrkingsareal for bygg" },
            ]}
            required
            multi={false}
            value={valueProperty}
            onChange={
              setValueProperty as (
                value: string | number | boolean | null
              ) => void
            }
          />
        </TranslatorProvider>
        <TimeStepStats
          data={timeStepData}
          prodStats={prodStats}
          colorScale={ordColorScale!}
        />
      </>
    )
}