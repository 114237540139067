import { useMemo, useRef, useCallback } from "react";
import "./TimeSlider.scss";
import Button from "@avinet/adaptive-ui-core/ui/Button";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import { DateUtils } from "../utils/DateUtils";

export type TimeStep = {
  timestamp: Date;
  bandIndex: number;
};

export interface TimeSliderProps {
  timeSteps: TimeStep[];
  setTimeStep: (v: TimeStep) => void;
  currentTimeStep: TimeStep;
}

export function TimeSlider({
  timeSteps,
  setTimeStep,
  currentTimeStep,
}: TimeSliderProps) {
  const to = useRef<NodeJS.Timeout>();

  const [min, max] = useMemo(() => {
    return [timeSteps[0].bandIndex, timeSteps[timeSteps.length - 1].bandIndex];
  }, [timeSteps]);

  const changeTimeStep = useCallback(
    (
      timeStep: TimeStep,
      stepChange: number,
      play: boolean = true,
      duration: number = 500
    ) => {
      to.current = setTimeout(() => {
        var nst: number = timeStep.bandIndex + stepChange;
        var stop: boolean = false;
        if (nst > 0 && nst < max) {
          timeStep = timeSteps[nst - 1];
          if (play) changeTimeStep(timeStep, stepChange, play, duration);
        } else {
          timeStep = timeSteps[0];
          stop = true;
        }
        setTimeStep(timeStep);
        return stop;
      }, 500);
    },
    [max, setTimeStep, timeSteps]
  );

  if (!timeSteps) return null;

  return (
    <div className="time-slider">
      <Row>
        <Column className="text-small">
          {DateUtils.dateTime(timeSteps[0].timestamp)}
        </Column>
        <Grow>
          <div className="slidecontainer">
            <input
              type="range"
              min={min}
              max={max}
              value={currentTimeStep.bandIndex}
              className="slider"
              onChange={(evt: any) => {
                clearTimeout(to.current);
                var idx = evt.target.value;
                setTimeStep(timeSteps[idx - 1]);
              }}
            />
          </div>
        </Grow>
        <Column className="text-small">
          {DateUtils.dateTime(timeSteps[timeSteps.length - 1].timestamp)}
        </Column>
      </Row>
      <Row className="time-display-container">
        <Column className="text-small full-width" align="center">
          {DateUtils.dateTime(currentTimeStep.timestamp)} / T+
          {currentTimeStep.bandIndex} timer etter hendelse (eksempeldata,
          Tsjernobyl-ulykken (1986))
        </Column>
      </Row>
      <Row>
        <div className="btn-group">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              changeTimeStep(currentTimeStep, 1);
            }}
          >
            Play
          </Button>
          <Button
            size="small"
            color="danger"
            onClick={() => {
              clearTimeout(to.current);
            }}
          >
            Stop
          </Button>
        </div>
      </Row>
    </div>
  );
}
