import { timeFormat } from "d3-time-format";


export class DateUtils {
    static _dateTime = timeFormat("%d.%m.%Y %H:%M");
    static _date = timeFormat("%d.%m.%Y");

    static dateTime = (dt : Date | string) => {
        dt = new Date(dt);
        return DateUtils._dateTime(dt);
    }
}