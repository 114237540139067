import './TimeStepStats.scss';

import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import { ScaleOrdinal } from "d3-scale";
import Row from "@avinet/adaptive-ui-core/layout/Row";

type CustomTooltipProps = { active? : boolean, payload? : any};

const CustomTooltip = ({ active, payload } : CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].name} : ${Math.round(payload[0].value / 1000)} ha`}</p>
      </div>
    );
  }

  return null;
};

export function TimeStepStats({
  data,
  prodStats,
  colorScale,
}: {
  data: any;
  prodStats: any;
  colorScale: ScaleOrdinal<string, any>;
}) {

  // const display = useMemo(() => {
  //   return data.map((rec: any, recIdx: number) => (
  //     <tr key={`row-${recIdx}-${rec.key}`}>
  //       <td>{rec.key}</td>
  //       <td style={{ textAlign: "right" }}>
  //         {Num.roundNearest(rec.value, 0.2).toFixed(2)}
  //       </td>
  //     </tr>
  //   ));
  // }, [data]);

  return (
    <div>
      <h4>Påvirket dyrkningsareal</h4>
      <small>Arealer er oppgitt i hele 1000 hektar (ha) og er fargelagt etter påvirkningsgrad</small>
      <Row>
          <PieChart width={300} height={600}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="key"
              cx="50%"
              cy="50%"
              outerRadius={100}
              innerRadius={60}
              isAnimationActive={false}
              labelLine
              label={(a) => {
                return `${Math.round(a.value/1000)}' ha`;
              }}
            >
              {data.map((d: any, i: any) => {
                return (
                  <Cell
                    key={`pie-key-${d.key}`}
                    fill={colorScale(d.key.substring(3))}
                  />
                );
              })}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="bottom"
              layout="horizontal"
              align="center"
              width={200}
              iconSize={10}
              formatter={(value, entry, index) => (
                <span className="legend-key-item">{value}</span>
              )}
            />
          </PieChart>
      </Row>
      {/* <Row>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Akkumulert Bq/m3</th>
              <th>Dyrkingsareal poteter (ha)</th>
            </tr>
          </thead>
          <tbody>{display}</tbody>
        </table>
      </Row> */}
    </div>
  );
}
