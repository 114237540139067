import React from "react";
import "./ToggleButton.scss";

export interface ToggleButtonProps {
  /** Zoom in button tool tip */
  title?: string;
  /** Override zoom in icon. */
  icon?: React.ReactNode;
  /** Toggle function */
  handler: () => void;
}

/**
 * Add the Zoom control to the map (zoom in/zoom out buttons).
 */
export default function ToggleButton({
  title,
  icon = undefined,
  handler = () => console.error("Not implemented"),
  className = ""
}: ToggleButtonProps & React.HTMLAttributes<HTMLDivElement>) {

  return (
    <button
      type="button"
      className={`btn map icon toggle-button ${className}`}
      title={title}
      onClick={handler}
    >
      {icon}
    </button>
  );
}
