import React from "react";
import './Overlay.scss';
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import Column from "@avinet/adaptive-ui-core/layout/Column";

type OverlayProps = {
    show: boolean,
    width: number,
    onClose: ()=>void,
}

export function Overlay({show, width, onClose, children} : OverlayProps & React.HTMLAttributes<HTMLDivElement>) {
    if (!show) return null;
    return (<div className="overlay-background" onClick={()=>onClose()}>
        <div className="overlay-dialog" style={{width: width}}>
            <Row>
                <Grow></Grow>
                <Column maxWidth={50}>
                    <button onClick={()=>onClose()}>X</button>
                </Column>
            </Row>
            {children}
        </div>
    </div>)
}