import Switch from "@avinet/adaptive-ui-core/ui/Switch";
import { MenuItem } from "react-pro-sidebar";

export function LayerItem({
  name,
  visible,
  setVisible,
}: {
  name: string;
  visible: boolean;
  setVisible: () => void;
}) {
  return (
    <MenuItem
      suffix={
        <Switch
          title="Toggle layer visibility"
          value={visible}
          onChange={setVisible}
        ></Switch>
      }
    >
      {name}
    </MenuItem>
  );
}
