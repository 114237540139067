import { AuthContext } from "../context/AuthContextProvider";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import Form from "@avinet/adaptive-ui-core/form/Form";
import { TranslatorProvider } from "@avinet/adaptive-ui-translate";
import Input from "@avinet/adaptive-ui-core/form/controls/Input";
import Button from "@avinet/adaptive-ui-core/ui/Button";
import { AdaptiveUi } from "@avinet/adaptive-ui-core";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import "./LoginView.scss";
import Dialog from "@avinet/adaptive-ui-core/ui/Dialog";
import LdirLogo from "../assets/ldir-logo.svg";
import { getApiUrl } from "../utils/DataIO";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";

export function LoginView() {
  const authContext = useContext(AuthContext);

  const login = useCallback(
    (username: string, password: string) => {
      fetch(getApiUrl("authenticate"), {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then(async (res) => {
          var resText = await res.text();
          if (res.status !== 200) throw new Error(resText);
          authContext.dispatch({
            type: "login",
            payload: {
              isAuth: true,
              jwt: resText,
              username: username,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [authContext]
  );

  return (
    <AdaptiveUi local theme={"adaptive"}>
      <Dialog className="login-view">
        <Row className="ldir-logo-container">
          <img
            className="ldir-logo"
            src={LdirLogo}
            alt="Landbruksdirektoratet"
          />
        </Row>
        <Row>
          <TranslatorProvider
            translations={{
              locale: "no_nb",
              Form: {
                textLabel: "Text",
                optional: "valgfri",
                usernameLabel: "Brukernavn",
                passwordLabel: "Passord",
              },
            }}
          >
            <Form
              onSubmit={
                (data: any) => login(data.username, data.password)
                // authContext.dispatch({ type: "login", payload: data })
              }
            >
              <Row>
                <Input type="text" id="username" required />
              </Row>
              <Row>
                <Input type="password" id="password" required />
              </Row>
              <Row>
                <Grow>
                  <div className="text-end">
                    <Button type="submit">Login</Button>
                  </div>
                </Grow>
              </Row>
            </Form>
          </TranslatorProvider>
        </Row>
      </Dialog>
    </AdaptiveUi>
  );
}
