import { useEffect, useState, useCallback } from "react";
import useLayerOpacity from "@avinet/adaptive-ui-maps/hooks/useLayerOpacity";
import useLayerZIndex from "@avinet/adaptive-ui-maps/hooks/useLayerZIndex";
import useLayerEvents from "@avinet/adaptive-ui-maps/hooks/useLayerEvents";
import { useMapContext } from "@avinet/adaptive-ui-maps";
import { LayerProps } from "@avinet/adaptive-ui-maps/types/LayerProps";
import LayerContext from "@avinet/adaptive-ui-maps/layer/LayerContext";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { TimeStep } from "./TimeSlider";
import crossfilter from "crossfilter2";
import { getApiUrl } from "../utils/DataIO";
import { Circle, Fill, Style } from "ol/style.js";

export interface LdirImageLayerProps extends LayerProps {
  /**
   * Set to "anonymous" to avoid cross origin contamination of map canvas.
   */
  crossOrigin?: "anonymous";
  timeStep: TimeStep;
  setTimeStepData: (data: any) => void;
  colorScale: any,
  radiationClassification: any,
  valueProperty?: string
}

const GeoJSONFormat = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});

export function LdirGeoJsonLayer({
  children,
  opacity = 1,
  maxZoom,
  minZoom,
  zIndex = 0,
  attributions,
  onPreRender,
  onPostRender,
  timeStep,
  setTimeStepData,
  colorScale,
  radiationClassification,
  valueProperty = 'area'
}: LdirImageLayerProps) {
  const { map, view, auth } = useMapContext();
  const setLayerZIndex = useLayerZIndex(zIndex);
  const setLayerOpacity = useLayerOpacity(opacity);
  const setLayerEvents = useLayerEvents({ onPreRender, onPostRender });
  const [_layer, setLayer] = useState<VectorLayer<VectorSource> | undefined>();

  const updateTimeStepData = useCallback(async () => {
    var dt = new Date(timeStep.timestamp);
    var url = getApiUrl(`geojson/3857?dateTime=${dt.toISOString()}`);
    var fc = await fetch(url).then((res) => res.json());

    if (Array.isArray(fc.features)) {
      var cf = crossfilter(fc.features);
      var dim = cf.dimension((d: any) => {
        var c = (radiationClassification as any[]).findIndex((o)=>d.properties.value >= o.low && d.properties.value < o.high);
        return `${String(c + 1).padStart(2, "0")} ${radiationClassification[c].label}`;
      });
      var grp = dim.group().reduceSum((g: any) => g.properties[valueProperty]);
      setTimeStepData(grp.all().slice(0));

      var idValueMap = fc.features.reduce(
        (p: { [key: string]: number }, c: any) => {
          p[c.properties.id] = c.properties.value;
          return p;
        },
        {}
      );
      var currentFeatures = _layer?.getSource()?.getFeatures();
      if (Array.isArray(currentFeatures) && currentFeatures.length > 0) {
        currentFeatures.forEach((feat) => {
          feat.set("value", idValueMap[feat.get("id")]);
        });
      } else {
        _layer?.getSource()?.addFeatures(GeoJSONFormat.readFeatures(fc));
      }
    }
  }, [_layer, radiationClassification, setTimeStepData, timeStep.timestamp, valueProperty]);

  useEffect(() => {
    updateTimeStepData();
  }, [_layer, timeStep, updateTimeStepData]);

  useEffect(() => {
    var lyr: VectorLayer<VectorSource>;

    async function _inner() {
      const source = new VectorSource({
        format: GeoJSONFormat,
      });

      lyr = new VectorLayer({
        source: source,
        style: (feat)=>{
          var _color = colorScale(feat.get("value"));
          return new Style({
            image: new Circle({
              fill: new  Fill({
                color: _color === "rgb(255, 255, 255)" ? 'rgba(255,255,255,0)' : _color
              }),
              radius: 3
            })
          });
        }
      });

      setLayerEvents(lyr);
      setLayerZIndex(lyr);
      setLayerOpacity(lyr);
      setLayer(lyr);
      map.addLayer(lyr);
    }

    _inner();

    return () => {
      map.removeLayer(lyr);
      setLayer(undefined);
      setLayerEvents(undefined);
      setLayerZIndex(undefined);
      setLayerOpacity(undefined);
    };
  }, [map, view, attributions, auth, maxZoom, minZoom, setLayerEvents, setLayerZIndex, setLayerOpacity, colorScale]);

  return (
    <LayerContext.Provider value={{ layer: _layer }}>
      {children}
    </LayerContext.Provider>
  );
}
