import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import LdirLogo from "./assets/ldir-logo.svg";

import "./App.scss";
import { AdaptiveUi } from "@avinet/adaptive-ui-core";
import Row from "@avinet/adaptive-ui-core/layout/Row";
import Column from "@avinet/adaptive-ui-core/layout/Column";
import Grow from "@avinet/adaptive-ui-core/layout/Grow";
import { MapProvider } from "@avinet/adaptive-ui-maps";
import { MapView } from "@avinet/adaptive-ui-maps";
import Controls from "@avinet/adaptive-ui-maps/control/Controls";
import OsmLayer from "@avinet/adaptive-ui-maps/layer/OsmLayer";
import { Container } from "./components/Container";
import ImageWmsLayer from "@avinet/adaptive-ui-maps/layer/ImageWmsLayer";
import { LdirImageLayer } from "./components/LdirImageLayer";
import { TimeSlider, TimeStep } from "./components/TimeSlider";
import { LdirGeoJsonLayer } from "./components/LdirGeoJsonLayer";
import WmtsLayer from "@avinet/adaptive-ui-maps/layer/WmtsLayer";
import { getApiUrl } from "./utils/DataIO";
import { scaleLinear, ScaleLinear, scaleOrdinal, ScaleOrdinal } from "d3-scale";
import { AuthContext } from "./context/AuthContextProvider";
import { LoginView } from "./views/LoginView";
import { LayerItem } from "./components/LayerItem";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import ToggleButton from "./map-controls/ToggleButton";
import { RightSidebar } from "./components/RightSidebar";

import {
  Filter20Regular,
  Map20Regular,
  Layer20Regular,
  ChartMultiple20Regular,
  Navigation20Regular
} from "@fluentui/react-icons";
import { Overlay } from "./components/Overlay";

// Additional icons used in this project
export const icons = {
  filter: <Filter20Regular />,
  map: <Map20Regular />,
  layer: <Layer20Regular />,
  chart: <ChartMultiple20Regular />,
  navigation: <Navigation20Regular/>
};

export type LayerVisibility = {
  visible: boolean;
  title: string;
  type: "theme" | "basemap";
};

export type VisibilityMatrix = {
  [s: string]: LayerVisibility;
  showBasemap: LayerVisibility;
  showImagery: LayerVisibility;
  showMunicipalities: LayerVisibility;
  showPropertyBoundaries: LayerVisibility;
  showCounties: LayerVisibility;
  showDispersion: LayerVisibility;
  showFarmGroups: LayerVisibility;
};

export type LayerKeys = keyof VisibilityMatrix;



function App() {
  const [timeStep, setTimeStep] = useState<TimeStep>();
  const [timeSteps, setTimeSteps] = useState<TimeStep[]>();
  const [showLeftSidebar, setShowLeftSidebar] = useState<boolean>(true);
  const [showRightSidebar, setShowRightSidebar] = useState<boolean>(false);
  const [valueProperty, setValueProperty] = useState<string>("area");
  const [showLayers, setShowLayers] = useState<VisibilityMatrix>({
    showImagery: { visible: false, title: "Satelittbilde", type: "basemap" },
    showBasemap: { visible: false, title: "Grunnkart", type: "basemap" },
    showCounties: { visible: false, title: "Fylkesgrenser", type: "theme" },
    showMunicipalities: {
      visible: false,
      title: "Kommunegrenser",
      type: "theme",
    },
    showPropertyBoundaries: {
      visible: false,
      title: "Eiendomsgrenser",
      type: "theme",
    },
    showDispersion: { visible: true, title: "Spredningsmodell", type: "theme" },
    showFarmGroups: {
      visible: true,
      title: "Landbrukseiendommer*",
      type: "theme",
    },
  });
  const [timeStepData, setTimeStepData] = useState<any[]>([]);
  const [prodStats, setProdStats] = useState<any[]>([]);
  const [colorScale, setColorScale] =
    useState<ScaleLinear<string, number, any>>();
  const [ordColorScale, setOrdColorScale] =
    useState<ScaleOrdinal<string, any>>();
  const [radiationClassification, setRadiationClassification] = useState<any>();
  const authContext = useContext(AuthContext);

  const changeVisibility = useCallback((lyrKey: string, visible: boolean) => {
    setShowLayers((lm) => {
      lm = { ...lm };
      lm[lyrKey as LayerKeys] = { ...lm[lyrKey as LayerKeys] };
      lm[lyrKey as LayerKeys].visible = visible;
      return lm;
    });
  }, []);

  const themeItems = useMemo(() => {
    return Object.keys(showLayers)
      .filter((lyrKey: string) => showLayers[lyrKey].type === "theme")
      .map((lyrKey: string, lyrIdx: number) => {
        return (
          <LayerItem
            key={`theme-${showLayers[lyrKey].title}`}
            name={showLayers[lyrKey].title}
            visible={showLayers[lyrKey].visible}
            setVisible={() =>
              changeVisibility(lyrKey, !showLayers[lyrKey].visible)
            }
          />
        );
      });
  }, [changeVisibility, showLayers]);

  const basemapItems = useMemo(() => {
    return Object.keys(showLayers)
      .filter((lyrKey: string) => showLayers[lyrKey].type === "basemap")
      .map((lyrKey: any, lyrIdx: number) => {
        return (
          <LayerItem
            key={`basemap-${showLayers[lyrKey].title}`}
            name={showLayers[lyrKey].title}
            visible={showLayers[lyrKey].visible}
            setVisible={() =>
              changeVisibility(lyrKey, !showLayers[lyrKey].visible)
            }
          />
        );
      });
  }, [changeVisibility, showLayers]);

  useEffect(() => {
    async function doAsync() {
      let _timeSteps = await fetch(getApiUrl(`/image/gettimesteps`))
        .then((res) => res.json())
        .catch((error) => {
          console.error(error);
          return [];
        });
      let _prodStats = await fetch(
        getApiUrl("/json/production-area-statistics")
      )
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
          return {};
        });

      let [_colorScale, _ordColorScale] = await fetch(
        getApiUrl("/json/radiation-sensitivity-classification")
      )
        .then((res: any) => {
          return res.json().then((radSensClassif: any) => {
            setRadiationClassification(radSensClassif);
            var _scaleLinear = scaleLinear<string, number, any>(
              radSensClassif.map((r: any) => r.high as number),
              radSensClassif.map((r: any) => r.color as string)
            );
            var _scaleOrdinal = scaleOrdinal(
              radSensClassif.map((r: any) => r.label),
              radSensClassif.map((r: any) => r.color)
            );
            return [_scaleLinear, _scaleOrdinal];
          });
        })
        .catch((err) => {
          console.error(err);
        });
      setColorScale(() => _colorScale);
      setOrdColorScale(() => _ordColorScale);
      setProdStats(_prodStats);

      setTimeSteps(_timeSteps);
      setTimeStep(_timeSteps[0]);
    }

    doAsync();
  }, []);

  if (!authContext.state.isAuth) {
    return <LoginView />;
  }

  if (!timeSteps || !timeStep || typeof colorScale !== "function") return null;

  return (
    <AdaptiveUi local theme={"adaptive"}>
      <MapProvider
        // extent={[-1000000, 7500000, 4500000, 12000000]}
        initialExtent={[-3110475, 4685866, 6450060, 11944034]}
        minZoom={3}
        maxZoom={16}
      >
        <Overlay width={300} show={showRightSidebar} onClose={()=>setShowRightSidebar(false)}>
          <RightSidebar
            ordColorScale={ordColorScale}
            setValueProperty={setValueProperty}
            valueProperty={valueProperty}
            timeStepData={timeStepData}
            prodStats={prodStats}
          />
        </Overlay>
        <Row className="main-screen">
          <Sidebar
            backgroundColor="#ffffff"
            onBackdropClick={() => setShowLeftSidebar(false)}
            toggled={showLeftSidebar}
            breakPoint="md"
            width="300px"
            style={{ zIndex: 1000 }}
          >
            <Column className="main-screen">
              <Row>
                <Container>
                  <img
                    className="ldir-logo"
                    src={LdirLogo}
                    alt="Landbruksdirektoratet"
                  />
                  <h3 style={{padding: ".25em 0", margin: 0}}>Atomberedskap</h3>
                </Container>
              </Row>
              <Grow>
                <Menu>
                  <SubMenu
                    icon={icons.map}
                    label="Bakgrunnskart   "
                    defaultOpen
                  >
                    {basemapItems}
                  </SubMenu>
                  <SubMenu
                    icon={icons.layer}
                    label="Temadata   "
                    defaultOpen
                  >
                    {themeItems}
                    <MenuItem><small>* = generaliserte til cellestørrelse i modell</small></MenuItem>
                  </SubMenu>
                </Menu>
              </Grow>
              <Row>
                <Menu>
                  <MenuItem
                    onClick={() => {
                      authContext.dispatch({
                        type: "logout",
                        payload: null,
                      });
                    }}
                  >
                    Logg ut {authContext.state.username}
                  </MenuItem>
                  <MenuItem>Adaptive 4 / Asplan Viak</MenuItem>
                </Menu>
              </Row>
            </Column>
          </Sidebar>
          <Grow minWidth={320}>
            <MapView height="100vh">
              <ToggleButton
                className="hide-from-md btn-open-left-sidebar"
                title="Meny"
                icon={icons.navigation}
                handler={() => setShowLeftSidebar((current) => !current)}
              />
              <ToggleButton
                className="hide-from-lg btn-open-right-sidebar"
                title="Meny"
                icon={icons.chart}
                handler={() => setShowRightSidebar((current) => !current)}
              />
              <Controls />
              <OsmLayer zIndex={50} />
              {showLayers.showBasemap.visible && (
                <ImageWmsLayer
                  url="https://openwms.statkart.no/skwms1/wms.topo4"
                  layers="topo4_WMS"
                  attributions="&copy; Kartverket"
                  zIndex={100}
                />
              )}
              {showLayers.showImagery.visible && (
                <WmtsLayer
                  url="https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2"
                  layer="Nibcache_web_mercator_v2"
                  matrixSet="default028mm"
                  style={"default"}
                  attributions="&copy; Kartverket"
                  zIndex={150}
                />
              )}
              {showLayers.showDispersion.visible && (
                <LdirImageLayer
                  opacity={0.75}
                  timeStep={timeStep}
                  zIndex={200}
                />
              )}
              {showLayers.showFarmGroups.visible && (
                <LdirGeoJsonLayer
                  timeStep={timeStep}
                  setTimeStepData={setTimeStepData}
                  zIndex={250}
                  colorScale={colorScale}
                  radiationClassification={radiationClassification}
                  valueProperty={valueProperty}
                />
              )}
              {showLayers.showCounties.visible && (
                <ImageWmsLayer
                  url="https://wms.geonorge.no/skwms1/wms.adm_enheter2"
                  layers="fylker_gjel"
                  attributions="&copy; Kartverket"
                  zIndex={275}
                />
              )}
              {showLayers.showMunicipalities.visible && (
                <ImageWmsLayer
                  url="https://wms.geonorge.no/skwms1/wms.adm_enheter2"
                  layers="kommuner_gjel"
                  attributions="&copy; Kartverket"
                  zIndex={270}
                />
              )}
              {showLayers.showPropertyBoundaries.visible && (
                <ImageWmsLayer
                  url="https://openwms.statkart.no/skwms1/wms.matrikkel"
                  layers="eiendomsgrense"
                  attributions="&copy; Kartverket"
                  zIndex={265}
                />
              )}
              <TimeSlider
                timeSteps={timeSteps}
                currentTimeStep={timeStep}
                setTimeStep={setTimeStep}
              />
              {/* <SearchField /> */}
            </MapView>
          </Grow>
          <Column className="right-bar">
            <RightSidebar
              ordColorScale={ordColorScale}
              setValueProperty={setValueProperty}
              valueProperty={valueProperty}
              timeStepData={timeStepData}
              prodStats={prodStats}
            />
          </Column>
        </Row>
      </MapProvider>
    </AdaptiveUi>
  );
}

export default App;
