import { useEffect, useState } from "react";
import useLayerOpacity from "@avinet/adaptive-ui-maps/hooks/useLayerOpacity";
import useLayerZIndex from "@avinet/adaptive-ui-maps/hooks/useLayerZIndex";
import useLayerEvents from "@avinet/adaptive-ui-maps/hooks/useLayerEvents";
import { useMapContext } from "@avinet/adaptive-ui-maps";
import { LayerProps } from "@avinet/adaptive-ui-maps/types/LayerProps";
import LayerContext from "@avinet/adaptive-ui-maps/layer/LayerContext";
import ImageLayer from "ol/layer/Image.js";
import ImageStatic from "ol/source/ImageStatic.js";
import { TimeStep } from "./TimeSlider";
import { getApiUrl } from "../utils/DataIO";

export interface LdirImageLayerProps extends LayerProps {
  /**
   * Set to "anonymous" to avoid cross origin contamination of map canvas.
   */
  crossOrigin?: "anonymous";
  timeStep: TimeStep;
}

export function LdirImageLayer({
  children,
  opacity = 1,
  maxZoom,
  minZoom,
  zIndex = 0,
  attributions,
  timeStep,
  onPreRender,
  onPostRender,
}: LdirImageLayerProps) {
  const { map, view, auth } = useMapContext();
  const setLayerZIndex = useLayerZIndex(zIndex);
  const setLayerOpacity = useLayerOpacity(opacity);
  const setLayerEvents = useLayerEvents({ onPreRender, onPostRender });
  const [layer, setLayer] = useState<ImageLayer<ImageStatic> | undefined>();
  const [imageExtent, setImageExtent] = useState<number[] | undefined>();

  useEffect(() => {
    if (!layer || !timeStep || !imageExtent) return;
    const source = new ImageStatic({
      url: getApiUrl(`image/getimage/3857/${timeStep.bandIndex}`),
      imageExtent: imageExtent,
      interpolate: false,
    });
    layer?.setSource(source);
    return () => {};
  }, [layer, timeStep, imageExtent]);

  useEffect(() => {
    var tileLayer: ImageLayer<ImageStatic>;

    async function _inner() {
      let imageExtent = await fetch(
        getApiUrl("image/getextent/3857")
      ).then((res) => res.json());

      const source = new ImageStatic({
        url: getApiUrl(`image/getimage/3857/1`),
        imageExtent: imageExtent,
        interpolate: false,
      });

      tileLayer = new ImageLayer({
        opacity: 0.1,
        source,
        minZoom,
        maxZoom,
      });

      setImageExtent(imageExtent);
      setLayerEvents(tileLayer);
      setLayerZIndex(tileLayer);
      setLayerOpacity(tileLayer);
      setLayer(tileLayer);
      map.addLayer(tileLayer);
    }

    _inner();

    return () => {
      map.removeLayer(tileLayer);
      setLayer(undefined);
      setLayerEvents(undefined);
      setLayerZIndex(undefined);
      setLayerOpacity(undefined);
    };
  }, [
    map,
    view,
    attributions,
    auth,
    maxZoom,
    minZoom,
    setLayerEvents,
    setLayerZIndex,
    setLayerOpacity,
  ]);

  return (
    <LayerContext.Provider value={{ layer: layer }}>
      {children}
    </LayerContext.Provider>
  );
}
